.modal-window {
  position: fixed;
  top: 36px;
  bottom: 48px;
  left: 44px;
  right: 44px;
  height: calc(100% - 36px - 48px);
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 9;
  overflow: hidden;
}
.modal-title {
  background-color: themed("color-mainnav-background");
  color: themed("color-button-primary-text");
  font-size: 24px;
  padding: 8px 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.close-modal {
  display: flex;
}
.modal-dialog {
  z-index: 1050;
}
.modal-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 0;
  cursor: auto !important;
}
