.tasks-per-hour-empty-col {
  border: 1px solid #f0f3f6;
  width: 100%;
  height: 100%;
}
.tasks-per-hour, .tasks-per-hour-empty-col {
  margin-right: 0.1rem;
  min-height: 2rem;
}
.table.calendar-table {
  table-layout: fixed;
}
.table.calendar-table td {
  padding: 0;
  height: 0;
  border: 0;
}
.clendar-hour-col {
  min-width: 2rem;
  width: 2rem;
}
.tasks-per-hour {
  height: 100%;
}
.tasks-per-hour div {
  width: 100%;
  font-size: 80%;
  color: #fff;
  padding: 0 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tasks-per-hour .calendar-task-url {
  color: rgb(255 255 255 / 70%);
}
.tasks-per-hour .calendar-task-url:hover {
  color: rgb(255 255 255 / 95%);
}
.tasks-per-hour .task_color_0 {
  background-color: #34568b;
}
.tasks-per-hour .task_color_1 {
  background-color: #ff6f61;
}
.tasks-per-hour .task_color_2 {
  background-color: #6b5b95;
}
.tasks-per-hour .task_color_3 {
  background-color: #88b04b;
}
.tasks-per-hour .task_color_4 {
  background-color: #f7cac9;
}
.tasks-per-hour .task_color_5 {
  background-color: #92a8d1;
}
.tasks-per-hour .task_color_6 {
  background-color: #955251;
}
.tasks-per-hour .task_color_7 {
  background-color: #b565a7;
}
.tasks-per-hour .task_color_8 {
  background-color: #009b77;
}
.tasks-per-hour .task_color_9 {
  background-color: #dd4124;
}
.tasks-per-hour .task_color_10 {
  background-color: #d65076;
}
.tasks-per-hour .task_color_11 {
  background-color: #45b8ac;
}
.tasks-per-hour .task_color_12 {
  background-color: #efc050;
}
.tasks-per-hour .task_color_13 {
  background-color: #5b5ea6;
}
.tasks-per-hour .task_color_14 {
  background-color: #9b2335;
}
.tasks-per-hour .task_color_15 {
  background-color: #dfcfbe;
}
.tasks-per-hour .task_color_16 {
  background-color: #55b4b0;
}
.tasks-per-hour .task_color_17 {
  background-color: #e15d44;
}
.tasks-per-hour .task_color_18 {
  background-color: #7fcdcd;
}
.tasks-per-hour .task_color_19 {
  background-color: #bc243c;
}
.tasks-per-hour .task_color_20 {
  background-color: #c3447a;
}
.tasks-per-hour .task_color_21 {
  background-color: #98b4d4;
}
